'use client';

import { NUMBER_PLATE_REGEX } from '@core/Constants/Validations';

import Button from '@gds/Button/Button';
import Grid from '@gds/Grid/Grid';
import { Input } from '@gds/Input/Input';
import { Typography } from '@gds/Typography/Typography';

import { InputSectionProps } from './DetailsInputForm.entity';

import styles from './DetailsInputForm.module.css';

export const InputSection = ({
  inputFormTitle,
  isError,
  strings,
  reg,
  mileage,
  isLoading,
  inputFormCtaCopy,
  onSubmit,
  handleChange,
  setIsError,
}: InputSectionProps) => {
  const handleBlur = () => {
    if (!NUMBER_PLATE_REGEX.test(reg)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  return (
    <>
      <div className={styles.formWrapper}>
        <Typography variant="h4" className={styles.inputFormTitle}>
          {inputFormTitle}
        </Typography>
        <Grid.Row
          component="form"
          onSubmit={onSubmit}
          align="middle"
          gutter={{ mobile: 40, tablet: 20, desktop: { m: 24 } }}
          className={styles.row}
        >
          <Grid.Col className={styles.col} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Input
              className={styles.input}
              dataTestId="tradein-reg"
              error={isError ? strings.invalidRegNumber : undefined}
              onChange={e => handleChange(e, 'reg')}
              onBlur={() => handleBlur()}
              name="registration"
              placeholder={strings.regNumber}
              value={reg}
              required
            />
          </Grid.Col>

          <Grid.Col className={styles.col} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Input
              className={styles.input}
              dataTestId="tradein-mileage"
              onChange={e => handleChange(e, 'mileage')}
              placeholder={strings.mileage}
              value={mileage}
              max="999999"
              min="0"
              name="mileage"
              pattern="\d*"
              required
              type="number"
            />
          </Grid.Col>

          <Grid.Col className={styles.col} width={{ mobile: 12, tablet: 4, desktop: 4 }}>
            <Button dataTestId="tradein-search" type="submit" loading={isLoading}>
              {inputFormCtaCopy}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </div>
    </>
  );
};
