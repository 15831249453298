'use client';

import { KenticoImage } from 'Components/ClientWrappers/KenticoImage';
import { TrackedLink } from 'Components/TrackedLink/TrackedLink';

import styles from './CarType.module.css';

interface CarProps {
  href: string;
  label: string;
  image: string[];
  description: string;
  priority: boolean;
}

const Car = (carType: CarProps) => {
  return (
    <TrackedLink href={carType.href || '/'}>
      <div className={styles.carType}>
        <div className={styles.image}>
          <KenticoImage
            src={carType.image[0]}
            alt={carType.label}
            fill
            style={{ objectFit: 'contain' }}
            sizes="25vw"
            loading="lazy"
          />
        </div>
        <div className={styles.carTypeTitle}>{carType.label}</div>
        <div>{carType.description}</div>
      </div>
    </TrackedLink>
  );
};

export default Car;
