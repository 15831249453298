/* eslint-disable @typescript-eslint/naming-convention */
'use client';

import { ReactNode, useState } from 'react';

import Link from 'next/link';

type TrackLinkProps = {
  href: string;
  children: ReactNode;
  passHref?: boolean;
  legacyBehavior?: boolean;
  className?: string;
  prefetch?: boolean;
  [key: string]: unknown;
};

export const TrackedLink = (props: TrackLinkProps) => {
  const { href, children, className, passHref, legacyBehavior } = props;
  const { prefetch = false } = props;

  const [shouldPrefetch, setShouldPrefetch] = useState(prefetch);

  return (
    <Link
      {...props}
      prefetch={shouldPrefetch}
      href={href ?? '/'}
      passHref={passHref}
      legacyBehavior={legacyBehavior}
      className={className}
      onMouseOver={() => setShouldPrefetch(true)}
    >
      {children}
    </Link>
  );
};
