export const MAX_STARS_COUNT = 5;

export const getBusinessUnitId = () => '5d526139dee7ff000199ccba';

export const getCaroTemplateId = () => '53aa8912dec7e10d38f59f36';

export const getTemplateId = (isFourStars = true) => {
  return isFourStars ? '5406e65db0d04a09e042d5fc' : '54ad5defc6454f065c28af8b';
};

export const getMappedLocale = () => 'en-GB';

export const getTrustPilotUrl = () =>
  'https://uk.trustpilot.com/review/heycar.co.uk?utm_medium=trustbox&utm_source=Slider';
