'use client';

import { formatNumber } from '@core/Utils/Intl/FormatNumber';
import { getFormattedCurrency } from '@core/Utils/Intl/GetFormattedCurrency';

import Button from '@gds/Button/Button';
import Grid from '@gds/Grid/Grid';

import Pill from '@gds/Pill/Pill';
import { Typography } from '@gds/Typography/Typography';
import Image from 'next/image';

import { ValuationSectionProps } from './DetailsInputForm.entity';

import styles from './DetailsInputForm.module.css';

export const ValuationSection = ({
  valuationHeader,
  valuationCopy,
  valuationFooter,
  valuationPrimaryCtaCopy,
  valuationSecondaryCtaCopy,
  valuation,
  locale,
  strings,
  handlePrimaryCtaClick,
  handleSecondaryCtaClick,
  handleNotYourCarClick,
}: ValuationSectionProps) => {
  const isFr = locale === 'fr';
  const distanceUnit = isFr ? 'km' : 'miles';

  return (
    <div className={styles.valuationWrapper}>
      <div className={styles.valuationContent}>
        <Grid.Row gutter={{ mobile: 40, tablet: 0 }}>
          <Grid.Col width={{ mobile: 12, tablet: 7, desktop: 7 }}>
            <div className={styles.valuationValue}>
              <Typography variant="body2" className={styles.valuationTitle}>
                {valuationHeader}
              </Typography>
              <Typography variant="h4" className={styles.valuationFigure}>
                {`${getFormattedCurrency(
                  valuation?.valuation.low,
                  locale,
                )} - ${getFormattedCurrency(valuation?.valuation.high, locale)}`}
              </Typography>
            </div>
            <Typography variant="body4" className={styles.bodyText}>
              {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
              <span dangerouslySetInnerHTML={{ __html: valuationCopy as string }} />
            </Typography>
            <div className={styles.valuationCtasContainer}>
              <Button fullWidth className={styles.valuationCTA} onClick={handlePrimaryCtaClick}>
                {valuationPrimaryCtaCopy}
              </Button>
              {valuationSecondaryCtaCopy && (
                <Button
                  fullWidth
                  variant="outlined"
                  className={styles.valuationCTA}
                  onClick={handleSecondaryCtaClick}
                >
                  {valuationSecondaryCtaCopy}
                </Button>
              )}
            </div>
            <Typography Component="div" variant="caption2" className={styles.footerDesktop}>
              {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
              <span dangerouslySetInnerHTML={{ __html: valuationFooter as string }} />
            </Typography>
          </Grid.Col>
          <Grid.Col width={{ mobile: 12, tablet: 4, desktop: 4 }} className={styles.vehicleCard}>
            {valuation?.reviews?.[0] && (
              <div className={styles.imageContainer}>
                <Image
                  src={valuation?.reviews[0]?.photos[0]?.big}
                  alt="Photo of car"
                  width={727}
                  height={484}
                  className={styles.imageContainer}
                />
              </div>
            )}
            <div className={styles.vehicleCardInformation}>
              <Typography variant={'subheading2'}>
                {valuation.vehicle.make} {valuation.vehicle.model}
              </Typography>
              <Typography variant="body4" className={styles.variant}>
                {valuation.vehicle.variant}
              </Typography>
              <div className={styles.labels}>
                <Pill className={styles.label} style={{ backgroundColor: '#FFFFFF' }}>
                  {valuation.vehicle.registration}
                </Pill>
                <Pill className={styles.label} style={{ backgroundColor: '#FFFFFF' }}>
                  {`${formatNumber(valuation.vehicle.mileage)} ${distanceUnit}`}
                </Pill>

                <Button
                  variant="link"
                  onClick={handleNotYourCarClick}
                  className={styles.notYourCarTablet}
                >
                  {strings.notYourCar}
                </Button>
              </div>
              <Button variant="link" onClick={handleNotYourCarClick} className={styles.notYourCar}>
                {strings.notYourCar}
              </Button>
            </div>
          </Grid.Col>
        </Grid.Row>
      </div>
      <Typography Component="div" variant="caption2" className={styles.footer}>
        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        <span dangerouslySetInnerHTML={{ __html: valuationFooter as string }} />
      </Typography>
    </div>
  );
};
