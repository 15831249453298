'use client';

import { FC, ReactElement, useEffect, useRef, useState } from 'react';

import { ChevronLeft } from '@gds/Icons/Paths/ChevronLeft';
import { ChevronRight } from '@gds/Icons/Paths/ChevronRight';
import { FullWidth } from '@page-builder/Components/FullWidth/FullWidth';

import styles from './Scroller.module.css';

type ScrollerProps = {
  scrollItemClass: string;
  children: ReactElement;
  currentVisibleItemIndex?: number;
  contained?: boolean;
};

export const Scroller: FC<ScrollerProps> = ({ children, scrollItemClass, contained }) => {
  const $scrollBox = useRef<HTMLDivElement>(null);
  const [isFirstVisible, setIsFirstVisible] = useState(true);
  const [isLastVisible, setIsLastVisible] = useState(false);

  useEffect(() => {
    const scrollItems = $scrollBox.current?.querySelectorAll(`.${scrollItemClass}`);
    if (!scrollItems) return;

    const observers = Array.from(scrollItems, (scrollItem, i) => {
      // @ts-expect-error - style is not on Element type
      scrollItem.style['scroll-snap-align'] = 'start';
      if (typeof IntersectionObserver === 'undefined') return;

      const observer = new IntersectionObserver(
        mutations =>
          mutations.forEach(mutation => {
            if (i === 0) setIsFirstVisible(mutation.isIntersecting);
            if (i === scrollItems.length - 1) setIsLastVisible(mutation.isIntersecting);
          }),
        { root: $scrollBox.current, threshold: 1 },
      );

      observer.observe(scrollItem);

      return observer;
    });

    return () => observers?.forEach?.(observer => observer?.disconnect());
  }, []);

  const scrollBy = (amount: number, isActive: boolean) => () => {
    if (isActive) {
      const $scrollItem = $scrollBox.current?.querySelector(`.${scrollItemClass}`);
      if (!$scrollItem) return;

      const itemSize = $scrollItem?.clientWidth || 0;
      $scrollBox.current?.scrollBy({ left: itemSize * amount, behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.wrapper}>
      {contained ? (
        <div className={`${styles.scrollBox} reviews-scrollbox`} ref={$scrollBox}>
          <div className={styles.scrollItems}>{children}</div>
        </div>
      ) : (
        <FullWidth>
          <div className={`${styles.scrollBox} reviews-scrollbox`} ref={$scrollBox}>
            <div className={styles.scrollItems}>{children}</div>
          </div>
        </FullWidth>
      )}
      <div className={`${styles.nav} reviews-nav`}>
        <ChevronLeft onClick={scrollBy(-1, !isFirstVisible)} data-is-active={!isFirstVisible} />
        <ChevronRight onClick={scrollBy(1, !isLastVisible)} data-is-active={!isLastVisible} />
      </div>
    </div>
  );
};
