'use client';

import { useCallback } from 'react';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { useModal } from '@core/Components/Modal/UseModal';
import { isWindowDefined } from '@core/Constants/Window';
import Grid from '@gds/Grid/Grid';
import { FullWidth } from '@page-builder/Components/FullWidth/FullWidth';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtom, useAtomValue } from 'jotai';
import { useRouter } from 'next/navigation';

import { locationAtom } from 'Atoms/Search/PostcodeAtom/LocationAtom';
import { postcodeAtom } from 'Atoms/Search/PostcodeAtom/PostcodeAtom';
import { RichText } from 'Components/RichText/RichText';
import { Campaign } from 'Entities/HeroCampaignBanner.entity';

import { generateHeroCampaignBannerTrackingData } from './HeroCampaignBanner.tracking';
import { HeroCampaignBannerModal } from './HeroCampaignBannerModal';

import styles from './HeroCampaignBanner.module.css';

export const HeroCampaignBanner = ({
  locale,
  colourScheme,
  fullText,
  isActive,
  shortText,
  text,
  title,
  url,
}: Campaign) => {
  const router = useRouter();
  const { openModal } = useModal();
  const shouldShowBanner = isActive[0].codename === 'true';
  const color = colourScheme[0].codename;
  const [postcode, setPostcode] = useAtom(postcodeAtom);
  const location = useAtomValue(locationAtom);
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);
  const hasLocation =
    location?.location && !(location.location.lat === 0 && location.location.lon === 0);
  const handleClick = useCallback(() => {
    const trimUrl = url.trim();

    if (trimUrl) {
      const localeWithUrl = trimUrl.startsWith(`${locale}`) ? trimUrl : `${locale}/${trimUrl}`;
      if (trimUrl.includes('postcode')) {
        const urlParams = new URLSearchParams(trimUrl);
        const urlPostcode = urlParams.get('postcode');
        if (urlPostcode === '') {
          setPostcode({ [locale]: '' });
          router.push(`/${localeWithUrl}`);
          return;
        }
      }

      const params = [];
      if (postcode[locale] && !trimUrl.includes('postcode')) {
        params.push(`postcode=${postcode[locale]}`);
      }
      if (hasLocation && !trimUrl.includes('lat') && !trimUrl.includes('lon')) {
        params.push(`lat=${location.location.lat}`);
        params.push(`lon=${location.location.lon}`);
      }

      const connector = params ? (trimUrl.includes('?') ? '&' : '?') : '';

      const trackingUrl = isWindowDefined ? window.location.origin + `/${localeWithUrl}` : '';
      const { event, context } = generateHeroCampaignBannerTrackingData({
        searchParams,
        userId,
        formState,
        ctaType: 'banner',
        ctaWording: `${trimUrl} hero campaign banner`,
        ctaUrl: trackingUrl,
      });

      trackCustomEvent({ event, context });

      router.push(`/${localeWithUrl}${connector}${params.join('&')}`);
      return;
    }
    openModal(<HeroCampaignBannerModal content={{ color, shortText, fullText }} />, {
      header: title,
    });
  }, [
    url,
    postcode,
    locale,
    location,
    color,
    shortText,
    fullText,
    title,
    router,
    setPostcode,
    openModal,
  ]);

  if (!shouldShowBanner) return null;

  return (
    <FullWidth>
      <div className={styles.wrapper} data-color={color} onClick={handleClick}>
        <Grid.Container>{text && <RichText richText={text} />}</Grid.Container>
      </div>
    </FullWidth>
  );
};

HeroCampaignBanner.displayName = 'HeroCampaignBanner';
