'use client';

import React from 'react';
import styles from './Styles/Default.module.css';

interface StarReviewRatingProps {
  rating: number;
}

const StarReviewRating: React.FC<StarReviewRatingProps> = ({ rating }) => {
  const filledStars = Math.floor(rating);
  const partialFill = rating % 1;

  /**
   * renderStar function generates an SVG for a filled star.
   */
  const renderStar = (i: number, fill: string, stroke: string) => (
    <svg
      key={`star-${i}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
      height="24"
      width="24"
    >
      <path d="M12 2L14.4 8.5H21.5L15.8 13.1L18.2 19.5L12 15.7L5.8 19.5L8.2 13.1L2.5 8.5H9.6L12 2z" />
    </svg>
  );

  /**
   * emptyStar SVG for an empty star.
   */
  const emptyStar = (i: number) => (
    <svg
      key={`empty-star-${i}`}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4996 3.27832L15.5148 9.3878L22.257 10.3675L17.3783 15.1231L18.53 21.838L12.4996 18.6676L6.46919 21.838L7.62089 15.1231L2.74219 10.3675L9.48439 9.3878L12.4996 3.27832ZM12.4996 7.79742L10.8125 11.2158L7.04011 11.764L9.76986 14.4248L9.12545 18.182L12.4996 16.4081L15.8738 18.182L15.2293 14.4248L17.9591 11.764L14.1867 11.2158L12.4996 7.79742Z"
        fill="#B5B5B5"
      />
    </svg>
  );

  /**
   * partialStart SVG for a half filled star.
   */

  const partialStar = (i: number) => (
    <svg
      key={`partial-star-${i}`}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5008 3L9.56187 8.95492L2.99023 9.90983L7.74552 14.5451L6.62295 21.0902L12.5008 18V3Z"
        fill="#FAC361"
      />
      <path
        d="M12.5 18.1296L12.5001 18.1296L18.5305 21.2999L17.3788 14.585L22.2575 9.82941L15.5153 8.84971L12.5001 2.74023L12.5 2.74042V7.25952L12.5001 7.25934L14.1872 10.6777L17.9596 11.2259L15.2298 13.8867L15.8742 17.6439L12.5001 15.87L12.5 15.8701V18.1296Z"
        fill="#FAC361"
      />
    </svg>
  );

  /**
   * Generate an array of stars based on the rating.
   */
  const stars = Array.from({ length: 5 }, (_, i) => {
    if (i < filledStars) {
      return renderStar(i, '#FAC361', 'none');
    } else if (i === filledStars && partialFill > 0) {
      return partialStar(i);
    } else {
      return emptyStar(i);
    }
  });

  /**
   * Render the StarReviewRating component with the generated stars.
   */
  return <div className={styles.starRating}>{stars}</div>;
};

export default StarReviewRating;
