'use client';

import { useRef } from 'react';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { VehicleImage } from '@core/Entities/Vehicle/VehicleImage.entity';

import { useImageCarousel } from 'Components/ImageCarousel/Hooks/UseImageCarousel';
import { ImageCarousel } from 'Components/ImageCarousel/ImageCarousel';
import { IMAGE_SIZES } from 'Components/ImageCarousel/ImageCarousel.constants';

export const ImageCarouselWrapper = ({
  images,
  strings,
  size = 'review',
  locale,
}: {
  images: VehicleImage[];
  strings: Record<string, string>;
  size?: string;
  locale: Locale;
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const { currentIndex, dragStart, dragging, dragEnd, moveTo, fromIndex, toIndex } =
    useImageCarousel(carouselRef);

  const carouselProps = {
    currentIndex,
    dragStart,
    dragging,
    dragEnd,
    moveTo,
    fromIndex,
    toIndex,
    carouselRef,
  };

  return (
    <ImageCarousel
      carouselProps={carouselProps}
      images={images}
      index={0}
      preloadFirstImg
      size={size as keyof typeof IMAGE_SIZES}
      strings={strings}
      locale={locale}
    />
  );
};
